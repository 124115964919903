import React from "react"
import Page from "src/components/page"
import Section from "src/components/section"
import c from "classnames"
import Markup from "src/components/markup"
import { useReviewsStore } from "src/state/reviews"
import { redCircle, blueCircle, quoteGraphic } from "src/components/svgs"

const ReviewsPage = () => {
  const { meta, title, reviews } = useReviewsStore()

  return (
    <Page {...meta}>
      <Section className="section-1">
        <h2 className="title c-2 parallax-1">
          <Markup>{title}</Markup>
        </h2>
        <div className="reviews">
          {reviews.map((review, i) => (
            <div key={i} className={"review-group"}>
              <div className={c("review two", i % 2 === 0 ? "parallax-1a" : "parallax-0")}>
                <div className="bg">
                  <Markup>{i % 2 === 0 ? redCircle : blueCircle}</Markup>
                </div>
                <div className="quote-graphic">
                  <Markup>{quoteGraphic}</Markup>
                </div>
                <div className="review-content">
                  <p className="body">
                    <Markup>{review.body1}</Markup>
                  </p>
                  <div className="source">
                    <div className="border" />
                    <p>
                      <Markup>{review.source1}</Markup>
                    </p>
                  </div>
                </div>
              </div>
              <div className={c("review two", i % 2 === 0 ? "parallax-0" : "parallax-1a")}>
                <div className="bg">
                  <Markup>{i % 2 === 0 ? redCircle : blueCircle}</Markup>
                </div>
                <div className="quote-graphic">
                  <Markup>{quoteGraphic}</Markup>
                </div>
                <div className="review-content">
                  <p className="body">
                    <Markup>{review.body2}</Markup>
                  </p>
                  <div className="source">
                    <div className="border" />
                    <p>
                      <Markup>{review.source2}</Markup>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Section>
    </Page>
  )
}

export default ReviewsPage
