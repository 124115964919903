import create from "zustand"

export const useReviewsStore = create((set) => ({
  meta: {
    id: "reviews",
    title: "Book Reviews - The Partnership Economy",
    description:
      "The Partnership Economy Book. Read Reviews by some of the industry leaders and experts.",
  },
  title: "Reviews for <i>The Partnership Economy</i>",
  reviews: [
    {
      body1: `<b><i>The Partnership Economy</i> is full of a-ha moments</b> for anyone who’s been paying attention. . . . It crystalizes why and how the affiliate model evolved into a new, distinct channel. The basic concept of referrals is the same, but the potential configurations, the consumer focus, and the lynchpin of trust are groundbreaking.`,
      source1: `Neil Patel, owner, NP Digital, and author of <i>Hustle</i>`,
      body2: `As the digital economy grows globally, partnerships will be the most important frontier. impact.com has been a leading light in creating a booming partnership economy. <b>Dave Yovanno’s wisdom is not just prescient, it’s timely</b>.`,
      source2: `Sunir Shah, CEO, AppBind, and president, Cloud Software Association`,
    },
    {
      body1: `<b>Partnerships are now one of the main food groups for business growth, and <i>The Partnership Economy</i> is the essential cookbook</b>. Any MBA candidate needs it as a go-to-market primer, C-levels need it for growth strategy, marketers will get tactics and inspiration. Thank you, Dave Yovanno, for this comprehensive guide — it’s right on time.`,
      source1: `Michael McNerney, publisher, <i>Martech Record</i>`,
      body2: `<b>An eye-opener</b>. The quiet metamorphosis of affiliate marketing, the dramatic shift in consumer attitudes toward advertising — this book unpacks all the factors that make partnerships an absolutely essential growth engine for brands today. . . . <b>An outstanding introduction backed up by great case studies and solid data. It belongs on any executive’s bookshelf</b>.`,
      source2: `Bill Macaitis, former CMO of Slack, Zendesk, and Salesforce`,
    },
    {
      body1: `Consumers are looking for brands to show them — not tell them — they are trustworthy. <b>Dave Yovanno shares the context for how brand-consumer relationships have changed and actionable strategies</b> for how to build authentic partnerships that will help your business thrive.`,
      source1: `Stefania Pomponi, founder of Hella Social Impact, and author of <i>Influencer Marketing for Dummies</i>`,
      body2: `I’ve been complaining for years that there is no definitive work on partnerships and no ‘book’ to reference. Thank you, Dave, for answering the call because <b><i>The Partnership Economy</i> is the book I’ve been waiting for and that you must read now</b>.`,
      source2: `Jared Fuller, host and producer, <i>PartnerUp: The Partnerships Podcast</i>`,
    },
    {
      body1: `<b>A must-read</b>. . . . Really gets under the hood of what makes a powerful modern partnership and, crucially, how today’s brands can use them to achieve customer acquisition and retention, drive sales, and establish a strong, long-term brand reputation. . . . <b>A remarkably engaging and captivating read</b>.`,
      source1: `Julia Smith, founder and CEO, The Digital Voice`,
      body2: `<b>A great book for anybody interested in the wider universe of performance marketing</b>. As an industry that has previously been very focused on a narrow set of rules and tactics, focusing on a handful of metrics, it's great to read about the dramatic evolution that has transformed the space into a much more versatile and sustainable one.`,
      source2: `Niamh Butler-Walton, PerformanceIN`,
    },
    {
      body1: `Dave Yovanno shares what all marketing leaders will soon know: Partnerships are the future of marketing. Dave and his team at impact.com have continually been at the forefront of using technology to transform the partnership industry, and <b><i>The Partnership Economy</i> will show you a blueprint and case studies for success</b> in the brave new world of partnership marketing.`,
      source1: `Robert Glazer, CEO, Acceleration Partners, and #1 <i>Wall Street Journal</i>-bestselling author of <i>Elevate</i> and <i>Performance Partnerships</i>`,
      body2: `<b>Required reading for anyone in revenue acquisition</b>. The adoption of cloud technologies and social media has accelerated the scale and speed of change in the partner landscape, and author Dave Yovanno offers a clear and cohesive path forward.`,
      source2: `Patrick McCue, board advisor and former senior vice president, Global Partners and Okta`,
    },
    {
      body1: `<b>Partnerships are what make good companies great</b>. <i>The Partnership Economy</i> dives into detail about every different type of modern partnership and how they work. It's a great resource to understand the intricacies of partnerships and how they can benefit your business.`,
      source1: `Fred Schebesta, founder of Finder`,
      body2: `<b>Partner, collaborate, share, grow</b>! Read <i>The Partnership Economy</i> to learn how 1+1 always equals 3 when partnerships are part of the equation for successful revenue generation.`,
      source2: `Gabby Leibovich, co-founder, Catch, Scoopon, Luxury Escapes, and EatNow/Menulog`,
    },
  ],
}))
